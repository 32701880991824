<template>
  <div class="search">
    <HeroBlock :image="image" :icon="icon" :title="title" />
    <bread-crumb></bread-crumb>

    <div
      class="search__not container container__mini3"
      v-if="results.length === 0"
    >
      <p class="search__not-title">
        {{ t("Sorry, nothing was found for your query") }}
      </p>
      <form>
        <input
          v-focus
          v-model="query_param"
          id="search"
          name="query"
          type="text"
          :placeholder="t('Enter a query')"
        />
        <label for="search">search</label>
        <button type="reset" aria-label="сброс">сброс</button>
      </form>
      <p class="search__not-results">
        {{ t("Found") }} {{ results.length }} {{ t("result") }}
      </p>
      <span>{{ t("Nothing found") }}</span>
    </div>

    <div
      class="search__results container container__mini3"
      v-if="results.length > 0"
    >
      <p class="search__results-title">
        {{ t("There are results on your request") }}
      </p>
      <form>
        <input
          v-focus
          v-model="query_param"
          id="search_1"
          name="query"
          type="text"
          placeholder="Введіть запит"
        />
        <label for="search_1">search</label>
        <button type="reset" aria-label="сброс">сброс</button>
      </form>
      <p class="search__results-results">
        {{ t("Found") }} {{ results.length }} {{ t("results") }}
      </p>

      <div class="search__results-content">
        <div class="search__results-wrap">
          <div v-for="item in results" :key="item.id">
            <!-- Если есть картинка -->
            <div
              class="search__results-item results-with-img"
              v-if="item.image !== null"
            >
              <img
                :src="url + item.image"
                width="120"
                height="120"
                alt="search"
              />
              <div class="results-with-img__wrap">
                <div v-html="item.description"></div>
                <h2>{{ item.name }}</h2>
                <router-link :to="{ path: linkTree(item) }" tag="a">{{
                  t("more_details")
                }}</router-link>
              </div>
            </div>
            <!-- Если нету картинки -->
            <div
              class="search__results-item results-not-img"
              v-if="item.image === null"
            >
              <h2>{{ item.name }}</h2>
              <div v-html="item.description"></div>
              <router-link :to="{ path: linkTree(item) }" tag="a">{{
                t("more_details")
              }}</router-link>
            </div>
          </div>
        </div>
        <div class="search__results-article" v-if="results.length > 0">
          <h2>{{ t("Found in sections") }}:</h2>
          <ul>
            <li v-for="item in clear_services" :key="item">
              <router-link
                :to="{ path: '/' + item.parent + '/' + item.slug }"
                class="info-inner__item"
              >
                {{ item.name }}
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <FormCommon />
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";

const HeroBlock = defineAsyncComponent(() =>
  import("@/components/HeroBlock.vue")
);
const FormCommon = defineAsyncComponent(() =>
  import("@/components/FormCommon.vue")
);
const BreadCrumb = defineAsyncComponent(() =>
  import("@/components/BreadCrumb.vue")
);
import { useMeta } from "vue-meta";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import axios from "axios";

export default defineComponent({
  setup() {
    const { t } = useI18n();
    useMeta({
      title: t("Search Page"),
      htmlAttrs: {
        lang: localStorage.getItem("VueAppLanguage"),
        amp: true,
      },
    });
    return {
      t,
    };
  },
  components: {
    HeroBlock,
    FormCommon,
    BreadCrumb,
  },
  props: {
    url: {
      type: String,
      default: process.env.VUE_APP_API,
    },
    query: {
      default() {
        return "";
      },
    },
  },
  computed: {
    clear_services: {
      get() {
        return this.removeDuplicates(this.services);
      },
    },
    query_param: {
      get() {
        return this.$route.query.query;
      },
      set(value) {
        /* Здесь я использую замену */
        this.$router.replace({
          query: {
            ...this.$route.query,
            query: value,
          },
        });
        this.search(value);
      },
    },
  },
  data() {
    return {
      locale: this.$i18n.locale,
      image: "",
      icon: "",
      title: "",
      results: [],
      services: [],
    };
  },
  methods: {
    linkTree(item) {
      let link = "";

      if (item) {
        //Links for services
        if (item.parent !== undefined && item.parent !== null) {
          if (item.parent.parent !== undefined && item.parent.parent !== null) {
            if (
              item.parent.parent.slug !== "paketnye-resheniya" &&
              item.parent.parent.slug !== "komplektuyuci"
            ) {
              link += item.parent.parent.slug + "/";
            }
          }
          if (
            item.parent.slug !== "paketnye-resheniya" &&
            item.parent.slug !== "komplektuyuci"
          ) {
            link += item.parent.slug + "/";
          }
          link += item.slug;
          return link;
        }

        //Links for products
        if (item.service_id !== undefined && item.service_id !== null) {
          if (item.service_id.parent) {
            link += item.service_id.parent.slug + "/";
          }
          if (item.service_id) {
            link += item.service_id.slug;
          }
          return link;
        }
      }

      return link;
    },
    removeDuplicates(arr) {
      const result = [];
      const duplicatesIndices = [];

      // Перебираем каждый элемент в исходном массиве
      arr.forEach((current, index) => {
        if (duplicatesIndices.includes(index)) return;

        result.push(current);

        // Сравниваем каждый элемент в массиве после текущего
        for (
          let comparisonIndex = index + 1;
          comparisonIndex < arr.length;
          comparisonIndex++
        ) {
          const comparison = arr[comparisonIndex];
          const currentKeys = Object.keys(current);
          const comparisonKeys = Object.keys(comparison);

          // Проверяем длину массивов
          if (currentKeys.length !== comparisonKeys.length) continue;

          // Проверяем значение ключей
          const currentKeysString = currentKeys.sort().join("").toLowerCase();
          const comparisonKeysString = comparisonKeys
            .sort()
            .join("")
            .toLowerCase();
          if (currentKeysString !== comparisonKeysString) continue;

          // Проверяем индексы ключей
          let valuesEqual = true;
          for (let i = 0; i < currentKeys.length; i++) {
            const key = currentKeys[i];
            if (current[key] !== comparison[key]) {
              valuesEqual = false;
              break;
            }
          }
          if (valuesEqual) duplicatesIndices.push(comparisonIndex);
        } // Конец цикла
      });
      return result;
    },
    transformToService(array) {
      array.forEach((element) => {
        this.services.push({
          name: element.service_id.name,
          slug: element.service_id.slug,
          parent: element.service_id.parent.slug,
        });
      });
    },
    async search(query) {
      try {
        if (query.length > 0) {
          const config = {
            headers: {
              "Content-Type": "application/json",
            },
          };
          const response = await axios.get(
            process.env.VUE_APP_API +
              "/api/" +
              this.locale +
              "/search/" +
              query,
            config
          );
          this.results = response.data[1].data;

          response.data[0].data.forEach((element) => {
            this.results.push(element);
          });

          this.transformToService(this.results);
        } else {
          this.results = [];
        }
      } catch (e) {
        // alert("Ошибка запросса");
      } finally {
        //что-то делаем после завершения
      }
    },
    async getBanner() {
      try {
        let clear_url = this.$route.matched[0].path.replace("/uk/", "");
        clear_url = clear_url.replace("/ru/", "");
        clear_url = clear_url.replace("/", "");

        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const response = await axios.get(
          process.env.VUE_APP_API +
            "/api/" +
            this.locale +
            "/banners/search/" +
            clear_url,
          config
        );
        this.image = response.data.data[0].image;
        this.icon = response.data.data[0].icon[0].url;
        this.title = response.data.data[0].title;
      } catch (e) {
        // alert("Ошибка запросса");
      } finally {
        //что-то делаем после завершения
      }
    },
  },
  created() {
    this.search(this.query_param);
    this.getBanner();
  },
});
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/_mixins.scss";

.search {
  &__not,
  &__results {
    color: #1c1c1c;
    @include property("margin-bottom", 110, 30);
    &-title {
      font-weight: 700;
      margin-bottom: 30px;
      margin-top: 50px;
    }

    &-results {
      margin-bottom: 7px;
      margin-top: 25px;
    }

    &-wrap {
      > * {
        &:not(:first-child) {
          margin-top: 30px;
          padding-top: 25px;
          border-top: 1px solid #f9b80e;
        }
      }
    }
  }

  &__not {
    &-results {
      font-size: 20px;
    }
    span {
      color: #aeada9;
    }
  }

  &__results {
    &-results {
      color: #f9b80e;
      padding-bottom: 30px;
      margin-bottom: 55px;
      border-bottom: 1px solid #f9b80e;
    }

    &-content {
      display: grid;
      justify-content: space-between;
      grid-gap: 15px 25px;

      @media (min-width: 768px) {
        grid-template-columns: minmax(290px, 640px) 240px;
      }
    }

    &-article {
      color: #1d1d1b;

      h2 {
        font-weight: 700;
        font-size: 24px;
        margin-bottom: 13px;
      }

      ul {
        li {
          &:not(:first-child) {
            margin-top: 20px;
          }

          a {
            position: relative;
            font-size: 18px;
            padding-left: 20px;

            &::before {
              content: "";
              position: absolute;
              left: 0;
              top: 6px;
              width: 10px;
              height: 10px;
              border-radius: 50%;
              background: #1d1d1b;
              transition: all 0.3s linear;
            }

            @media (hover: hover) {
              &:hover {
                text-decoration: none;
                color: #f9b80e;

                &::before {
                  background: #f9b80e;
                }
              }
            }
          }
        }
      }
    }
  }
}

.results-with-img {
  display: grid;
  grid-template-columns: 120px 1fr;
  grid-gap: 23px;

  @media (max-width: 575.98px) {
    grid-gap: 15px;
    grid-template-columns: 1fr;
  }

  &__wrap {
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;

    p {
      font-size: 18px;
    }

    h2 {
      position: relative;
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 20px;
      letter-spacing: 1px;
      color: #f9b80e;
      margin-right: 10px;
      margin-bottom: 10px;

      &::before {
        content: "";
        display: block;
        width: 18px;
        height: 14px;
        flex: 0 0 auto;
        margin-right: 7px;
        background: url("~@/assets/images/tick_search.svg") no-repeat;
        background-size: 100% 100%;
      }
    }

    a {
      margin-left: auto;
      letter-spacing: 2.4px;
      color: #f9b80e;
    }
  }

  img {
    display: block;
    width: 120px;
    height: 120px;
    flex: 0 0 auto;
    margin: 0 auto;
    object-fit: cover;
  }
}

.results-not-img {
  display: flex;
  flex-direction: column;
  color: #1d1d1b;
  h2 {
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 13px;
  }

  a {
    align-self: flex-end;
    letter-spacing: 2.4px;
    color: #f9b80e;
  }
}

form {
  position: relative;
  label {
    position: absolute;
    top: 0;
    right: 0;
    visibility: hidden;
  }

  input {
    padding: 14px 65px 14px 30px;
    box-shadow: 0px 0px 20px #f9b80e1a;
    border: 1px solid #f9b80e;
    width: 100%;

    @media (max-width: 575.98px) {
      padding: 14px 45px 14px 15px;
    }
  }

  button {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    width: 34px;
    height: 34px;
    font-size: 0;
    background: #c2c2c2;
    mask-image: url("~@/assets/images/close.svg");
    mask-size: 20px 20px;
    mask-repeat: no-repeat;
    mask-position: center center;

    @media (max-width: 575.98px) {
      right: 5px;
    }
  }
}
</style>
